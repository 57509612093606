export const FirebaseConfig = {
  apiKey: "AIzaSyByogmWM4ykzLvZnONLth1kEtnmN66g2eY",
  authDomain: "yaatree-e8d6e.firebaseapp.com",
  databaseURL: "https://yaatree-e8d6e.firebaseio.com",
  projectId: "yaatree-e8d6e",
  storageBucket: "yaatree-e8d6e.appspot.com",
  messagingSenderId: "45073163970",
  appId: "1:45073163970:web:0df1d1f5cd299190c73d5b",
  measurementId: "G-LB5LCLKT2J"
};
